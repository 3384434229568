/* css */
html {
  scroll-behavior: smooth;
}

/**SIGNIN START**/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");

.sign-in-box {
  width: 100%;
  height: 100vh;
  background: #003091c7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-in-box .box {
  width: fit-content;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.sign-in-box .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
.sign-in-box .box .title .block {
  width: 0%;
  height: inherit;
  background: #ffb510;
  position: absolute;
  animation: mainBlock 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}
.sign-in-box .box .title h1 {
  font-family: "Poppins";
  color: #fff;
  font-size: 32px;
  -webkit-animation: mainFadeIn 1s forwards;
  -o-animation: mainFadeIn 1s forwards;
  animation: mainFadeIn 1s forwards;
  animation-delay: 0.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
  letter-spacing: 1px;
}
.sign-in-box .box .title h1 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffb510;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 1s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
}
.sign-in-box .box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: -10px;
}

.sign-in-box .box .role h2 {
  animation: secFadeIn 1s forwards;
  animation-delay: 0.8s;
  opacity: 0;
  font-family: "Lato";
  color: #000000;
  font-weight: bolder;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 5px;
  cursor: pointer;
}
.sign-in-box .box .role .hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffb510;
}
.sign-in-box .box .role .hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #ffb510;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}
.sign-in-box .box .role .hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

/**SIGNIN END**/
.ant-dropdown-menu {
  top: 0px !important;
  padding: 5px 2px !important;
  right: 20px !important;
}
.ant-dropdown-menu-item:hover {
  margin: 0 5px 0 5px !important;
  border-radius: 5px !important;
}
.ant-dropdown-menu .anticon svg {
  margin: -5px 0 0 0;
}
.ant-badge-count {
  top: 20px !important;
  right: 11.5px !important;
}
.ant-badge-dot {
  top: 25px !important;
  right: 23px !important;
  width: 8px !important;
  min-width: 8px !important;
  height: 8px !important;
}
.site-layout {
  background: #fafafa !important;
  letter-spacing: 0.49px !important;
}
.ant-layout-sider-light {
  top: 0px;
  left: 0px;
  z-index: 111;
  overflow: hidden !important;
  color: rgb(255, 255, 255) !important;
  transition: all 250ms ease-in-out 0s !important;
}
.ant-menu {
  background: transparent !important;
  color: rgba(76, 84, 96, 0.681) !important;
  overflow-y: auto !important;
  height: 85vh !important;
}
.ant-menu-item-selected {
  background: none !important;
  color: #5046e5 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  background: #5046e5 !important;
}
.ant-menu-item-selected a {
  color: #5046e5 !important;
}
.ant-menu-item-selected a:hover,
.ant-menu-item-selected:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-item a:hover {
  color: rgb(255, 255, 255) !important;
}

.ant-menu-item:hover,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-selected,
.ant-menu-submenu-arrow,
.ant-menu-submenu-title:hover {
  background: #5046e5 !important;
  color: rgb(255, 255, 255) !important;
}

.ant-menu-inline .ant-menu-item-selected::after {
  border-right: none !important;
}
.ant-menu-inline,
.ant-menu-vertical {
  border-right: none !important;
  margin: 15px 0 0 0 !important;
}
.sider-links:hover {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}

.ant-menu-item a {
  color: #454b55f2;
  font-size: 15px;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: none;
  padding: 10px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  margin: 0 0 0 15px !important;
  width: 227.5px !important;
  border-radius: 4px !important;
  height: 44px !important;
  white-space: pre !important;
  margin-bottom: 8px !important;
  text-decoration: none !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  transition: all 150ms ease-in 0s !important;
}
.brand-name {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: #5046e5;
  font-family: inherit;
  padding: 0px 45px 0px 0px;
}
.brand-logo {
  gap: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.app-name {
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
  font-size: 18px;
  display: block;
  width: 200px !important;
}

.admin-dash .sider-menu .anticon svg {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif Im !important;
}
.menu-subhead {
  font-size: small;

  text-transform: uppercase;
  color: #2c384aae;
  font-weight: 700;
  transition: height 0.15s, margin 0.15s;
  margin: 16px 0px 0px 16px;
  padding: 12px 16px 12px 16px;
}
.trigger {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #fff !important;
  padding: 15px;
  margin: 0 0 0 10px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}
.trigger:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #fff !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.bell {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #d8d8d8 !important;
}
.bell:hover {
  color: #ffffff !important;
}
.search {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: rgba(52, 49, 76, 1) !important;
  padding: 15px;
  margin: 0 0 0 5px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}
.search:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.admin-dash Header {
  top: 0;
  z-index: 96;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  height: 64px;
  position: sticky !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.avatar-user-details {
  border-radius: 24px !important;
  padding: 4px !important;
  color: rgba(52, 49, 76, 1) !important;
  float: right !important;
  margin: 10px 0 0 0;
}
.avatar-user-details:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.avatar-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.avatar-box span {
  margin: 0 6px;
}
.avatar-box .anticon svg {
  margin: -6px 0 0 0;
  font-size: 22px !important;
}
.avatar-name {
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
  color: #dadada;
}
.avatar-img {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.icon-search {
  float: right !important;
}
.details {
  display: inline-flex !important;
}
#count-cards-ids .anticon svg {
  font-size: 22px !important;
}
.count-cards .details .anticon svg {
  font-size: 30px !important;
  color: #5046e5 !important;
}
.count-cards .buttons .anticon svg {
  font-size: large;
  margin: -4px 0 0 0;
}
.count-cards .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;

  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  display: flex !important;
}

.dash-chart-count .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;
  border: none;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  display: flex !important;
}
.count-cards .card-body {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  padding: 24px !important;
  background: #fff !important;
}
.count-cards .count .name-cloud {
  color: rgba(52, 49, 76, 0.54) !important;
  font-size: 12px !important;
  font-weight: 550 !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  white-space: normal !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif Im !important;
}
.count-cards .count h6 {
  margin: 0 !important;
  margin-top: 0px !important;
  font-size: 16px !important;
  font-weight: 550 !important;
  color: #1976d2 !important;
}
.count-cards .card .buttons {
  float: right !important;
}
.count-cards .card .buttons .ant-btn-lg {
  padding: 6.4px 10px !important;
}
.count-cards .card .buttons:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}
/***************************Select ****************************************/
.query .anticon svg {
  font-size: 22px !important;
  color: #154b7f !important;
}
.ant-btn-text:hover {
  background: none !important;
}
.query .ant-badge-count {
  margin: -20px -10px 0px 0px !important;
}
.count-cards .count-cards-select .anticon svg {
  font-size: 16px !important;
  color: #5046e5 !important;
}
.count-cards .count-cards-select .buttons .anticon svg {
  opacity: 0.6 !important;
  font-size: large;
  margin: -4px 0 0 0;
}
.count-cards .count-cards-select .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border: none;
  border-radius: 8px !important;

  display: flex !important;
}
.count-cards-select .card:hover {
  transition: 0.3s linear all;
}

.count-cards-select .card-body {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  padding: 24px !important;
  background: #fff !important;
}
.count-cards-select .count .name-cloud {
  color: rgba(52, 49, 76, 0.54) !important;
  font-size: 12px !important;
  font-weight: 550 !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  white-space: normal !important;
}
.count-cards-select .count h6 {
  margin: 0 !important;
  margin-top: 4px !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  color: #1976d2 !important;
}
.count-cards-select .card .buttons {
  float: right !important;
}
.count-cards-select .card .buttons .ant-btn-lg {
  padding: 6.4px 10px !important;
}
.count-cards-select .card .buttons:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.list-accounts .card {
  padding: 0 0 10px 0 !important;
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
}
.list-accounts .card:hover {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 0px 1px 8px 0px rgb(0 0 0 / 22%) !important;
  transition: 0.3s linear all;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #232a44 !important;
}
.ant-carousel .slick-dots li button {
  background: #464c62 !important;
}
.ant-table-row:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.menu-position {
  position: fixed !important;
}
.ant-layout-footer {
  color: rgb(255, 255, 255) !important;
  --cui-bg-opacity: 1;
  background-color: rgba(80, 70, 229, var(--cui-bg-opacity)) !important;
}
.display-switch,
.collapsed-switch-visible {
  display: none;
}
/* .ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.25) !important;
  top: 18px;
  left: 35px;
}
.ant-switch-checked:focus {
  box-shadow: none !important;
} */
.ant-switch-handle:before {
  background-color: #ffffff !important;
}
.toogle-off {
  z-index: 9999 !important;
  top: 20px !important;
  position: fixed !important;
  left: 211px !important;
}
.toogle-on {
  z-index: 9999 !important;
  top: 5px !important;
  position: fixed !important;
  left: 23px !important;
}
.ant-layout-sider-collapsed .app-name {
  display: none !important;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px !important;
}
.ant-layout-sider-collapsed .brand-name {
  padding: 21px 29px;
  margin: 0px 0px 0px 0px;
}
.ant-layout-sider-collapsed .menu-subhead {
  white-space: normal;
  font-size: 10.5px;
  margin-top: 15px;
  margin-left: -10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #2c384aae;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  margin: 0 0 0 -6px !important;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  width: 0px !important;
  margin: 5px 0px 0 15px !important;
  border-radius: 5px !important;
}
/*COLLAPSED TOOGLE END*/
/***NEW CSS ENd***/

.bg-image-home {
  background-image: url("./assests/img/young-people-in-office.jpg") !important;
  height: 100vh;
}
.bg-image-home .card {
  border: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
}

.bg-image-home .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}
.home .anticon svg {
  color: #5046e5 !important;
}
.home {
  width: 50% !important;
  margin: 6% auto;
}
.navbar {
  background-color: #5046e5 !important;
}
.navbar-nav {
  margin: 0 0 0 auto !important;
}

/*************************ADMIN DASHBOARD*************************************/
.statistics-card .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
}

.statistics-card .cloud-charts h4 {
  letter-spacing: 2px;
}

.sign-out-link a {
  color: #5046e5 !important;
  letter-spacing: 1.2px;
  float: right;
  margin: 0 20px 0 0;
}
.sign-out-link a:hover {
  color: #5046e5 !important;
}

.admin-dash {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

.trigger:hover {
  color: #014d87 !important;
}
.add-button {
  float: right !important;
}
.pagination-div {
  float: right;
}
/************SIDER************/
.ant-layout-sider {
}

.logo-admin {
  height: 32px;
  margin: -50px 0px 150px 0;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  --cui-bg-opacity: 1;
  background-color: rgba(80, 70, 229, var(--cui-bg-opacity)) !important;
}

.bg-primary {
  --cui-bg-opacity: 1;
}

.profile-section-sider {
  text-align: center;
}
.profile-name-sider {
  margin: 15px 0 0 0;
  font-size: 7.5px;
  font-weight: bold;
  color: #5046e5;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 1px !important;
}
.profile-email-sider {
  font-size: 12px;
  margin: 10px 10px 25px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.avatar .anticon svg {
  margin: -10px 0 0 0 !important;
}
/************HEADING + BREADCrums************/
.ant-breadcrumb a {
  color: #5046e5 !important;
}
.ant-breadcrumb a:hover {
  color: #473be7 !important;
}
.pages-header {
  font-size: 32px;
  margin-bottom: 0;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important
    ;
  letter-spacing: 2px;
}
.ant-breadcrumb {
  float: right;
  margin: 5px 0 0 0;
}

/************CARD************/
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #002643, #8ae0ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #b98600, #fff946);
}

.bg-c-pink {
  background: linear-gradient(45deg, #500000db, #ff6e6e);
}

.dash-card .card {
  height: 160px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 19px 18px 20px 19px rgb(0 0 0 / 2%),
    7px 7px 13px 0px rgb(0 0 0 / 22%);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dash-card .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}
.dash-card .card .card-block {
  padding: 25px;
}
.dash-card h2 {
  letter-spacing: 2px;
}
.dash-card h3,
.dash-card p {
  letter-spacing: 1.5px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}
/***/

.dashboard .bg-warning {
  background-color: #ffbc58 !important;
  color: #ffffff;
}
.dashboard .bg-danger {
  background-color: #0270c4 !important;
  color: #ffffff;
}
.dashboard .bg-info {
  background-color: #13c9ca !important;
  color: #ffffff;
}
.dashboard .bg-secondary {
  background-color: #29f269 !important;
  color: #ffffff;
}
.dashboard .card-icon-cat {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #ffbc58;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}
.dashboard .card-icon-subcat {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #13c9ca;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}
.dashboard .card-icon-prod {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #5046e5;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}
.dashboard .card-icon-user {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #29f269;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}
/************CARD LINE DETAILS GRAPH @2nd ROW************/
.card-line-details p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

.card-line-details .card {
  border: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
}

.card-line-details .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}
.card-line-details .stats-line-percent {
  text-align-last: center;
}
.order-graph .card-header {
  background-color: #ffffff;
  border-bottom: none;
  padding: 30px;
  border-bottom: 1px solid #f8f8f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.order-graph h6 {
  color: #313131;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 20px;
}
.order-graph .card-body {
  padding: 30px;
  background-color: transparent;
}
.order-graph .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media .media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.order-graph .media .small-box {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/********************Categroy****************/
.ant-btn-default:hover {
  color: #4d43d8 !important;
  border-color: #4d43d8 !important;
}
.ant-btn-primary {
  color: white !important;
  border-color: #5046e5 !important;
  background: #5046e5 !important;
}
.ant-btn-primary:hover {
  border-color: #4d43d8 !important;
  background: #4d43d8 !important;
}
.ant-table table {
}
.progress-bar {
  background: white;
  padding: 10px 30px 10px 10px;
}
.category-list .card {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%),
    2px 5px 15px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 2px 5px 15px 0px rgb(0 0 0 / 18%);
}
.category-list .card:hover {
  box-shadow: 2px 2px 10px #dadada;
  transition: 0.3s linear all;
}
.ant-table {
  overflow: auto !important;
}
.ant-table-container {
}

/****************subCategory*****************/

.subCategory-admin .card {
  margin-right: 0px;
  margin-left: 35px;

  width: 240px;
  margin-bottom: 30px;
  border: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
}
.subCategory-admin .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.subCategory-admin .card .card-body {
  padding: 30px;
  background-color: transparent;
}
.subCategory-admin .card .card-body-deactive {
  padding: 30px;
  background-color: transparent;
  opacity: 0.75;
}
.subCategory-box {
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  vertical-align: middle;
}
.subCategory-box .img-wrapper,
.img-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.subCategory-box .img-wrapper .front,
.img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.image-subCategory {
  background-size: cover;
  background-position: center center;
  display: block;
}

.subCategory-admin .subCategory-box .front:hover .subCategory-hover {
  opacity: 1;
  border-radius: 0%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.subCategory-admin .subCategory-box .front .subCategory-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.subCategory-box .img-wrapper .front,
.img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.subCategory-admin .subCategory-box .front .subCategory-hover ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px;
}
.subCategory-admin .subCategory-box .front .subCategory-hover ul li {
  display: inline-block;
  -webkit-box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 8%);
  box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 8%);
  padding: 9px 14px;
  background-color: #ffffff;
  font-size: 18px;
  border-radius: 100%;
  line-height: 1.6;
  height: 45px;
  width: 45px;
  margin: 0 3px;
}
.subCategory-admin .subCategory-box .front .subCategory-hover ul li .btn {
  padding: 0;
  background-color: transparent;
}
.subCategory-admin .subCategory-box .front .subCategory-hover ul li .btn i {
  font-size: 16px;
}

.subCategory-box .subCategory-detail,
.subCategory-box .product-info,
.subCategory-detail {
  padding-left: 5px;
  margin-top: 15px;
}

.subCategory-box .subCategory-detail .heading {
  transition: 0.5s ease;
  text-decoration: none;
}
.subCategory-box .subCategory-detail .heading:hover {
  color: #5046e5;
  cursor: pointer;
}
.subCategory-box .subCategory-detail h6,
.subCategory-box h6,
.subCategory-detail h6 {
  font-weight: 550;
  color: #444444;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 16px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.subCategory-detail p {
  font-weight: 300;
  color: #777777;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.subCategory-detail h4 {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.subCategory-detail .anticon svg {
  margin-top: -4px !important;
}

.subCategory-dashboard .ant-pagination-options {
  display: none !important;
}
.subCategory-dashboard .ant-pagination-item-active {
  border-color: #5046e5 !important;
}
.subCategory-dashboard .ant-pagination-item:hover {
  border-color: #5046e5 !important;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #5046e5;
  border-color: #5046e5 !important;
}
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #5046e5 !important;
}
.ant-pagination-item-link .anticon svg {
  margin: -6px 0 0 0;
}
.btn-admin {
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 20px;
  font-weight: 550;
  -webkit-transition: 0.3s ease-in-out;
}
.subCategory-dashboard .btn-subCategory {
  display: inline-block;
  background: #5046e5 !important;
  color: #fff;
  border: 1px #5046e5;
  width: 190px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  cursor: pointer;
  letter-spacing: 2px;
}
.subCategory-dashboard .btn-subCategory:hover {
  background: #5046e5 !important;
}
.ant-modal-header {
}
.ok-modal {
  background: #5046e5 !important;
  border: 1px #5046e5 !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  font-weight: 550;
}

.ok-modal:hover {
  background: #5d52fa !important;
}
.close-modal {
  background-color: #f1c202 !important;
  border-color: #f1c202 !important;
  color: white !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  font-weight: 550;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}
.close-modal:hover {
  background-color: #f1c202 !important;
  border-color: #f1c202 !important;
  color: white !important;
}
.close-modal-delete {
  background-color: #c13939 !important;
  border-color: #f1c202 !important;
  color: white !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  font-weight: 550;
}
.close-modal-delete:hover {
  background-color: red !important;
  border-color: #ffbc58 !important;
  color: white !important;
}
.ant-modal-footer {
  border-top: none !important;
}

.subCategory-dashboard .circle-active {
  fill: #28a745;
  stroke: #28a745;
  stroke-width: 0.1875em;
}
.subCategory-dashboard .circle-inactive {
  fill: #dc3545;
  stroke: #dc3545;
  stroke-width: 0.1875em;
}
.ant-progress-bg {
  background-color: #e75362 !important;
}
/***********add admin***************/

.add-admin .card {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%),
    2px 5px 15px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 2px 5px 15px 0px rgb(0 0 0 / 18%);
}
.add-admin .card:hover {
  box-shadow: 2px 2px 10px #dadada;
  transition: 0.3s linear all;
}

.add-admin-button {
  display: inline-block;
  background: #fe7865 !important;
  color: #fff;
  border-bottom: none;
  width: 175px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  cursor: pointer;
}
.add-admin-button:hover {
  background: #ff5b45 !important;
}
/****************INput antd***************/
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #5046e5 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.ant-select-selector {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}
.registration .ant-input {
  height: 32.5px !important;
  letter-spacing: 1.5px !important;
}
.ant-modal-title,
.ant-select {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  letter-spacing: 1.5px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #5046e5 !important;
  box-shadow: 0 0 0 2px #5046e5 !important;
}
.ant-input:hover {
  border-color: #5046e5 !important;
}
/****************INput antd end***************/
/******ADD Purchase*****/
.add-purchase .ant-spin-spinning {
  display: list-item !important;
  margin: 225px 0 0 0 !important;
}
.add-purchase .ant-picker {
  width: -webkit-fill-available !important;
}
.add-purchase .save-purchase {
  width: -webkit-fill-available;
}
.add-purchase .card {
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
}
.add-purchase .card:hover {
  box-shadow: 4px 4px 20px #dadada !important;
  transition: 0.12s linear all;
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #5046e5 !important;
  border-color: #5046e5 !important;
}
.ant-btn-dangerous {
  color: #a10202 !important;
  border-color: #a10202 !important;
}
.ant-btn-dangerous:hover {
  color: #c40000 !important;
  border-color: #c40000 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #5046e5 !important;
  box-shadow: 0 0 0 2px #5046e5 !important;
}
.purchase-list .ant-table-content {
  max-height: 300px !important;
}
.final-purchases-list .ant-table-body {
  max-height: 55vh !important;
}
.list-accounts .ant-table-content {
  max-height: 47vh !important;
}
.table-row-dark {
  background-color: #ffcc81 !important;
}

/******Barcode**********/
.barcode-card {
  width: 50%;
  height: 410px;
  overflow-y: auto;
  margin: 2% 0 0 25%;
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-stop > svg {
  margin-top: -5px !important;
}
.ant-picker:hover {
  border-color: #5046e5 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5046e5 !important;
  border-color: #5046e5 !important;
}

/***********Search bar**********/
.header-right-wrap .same-style button {
  height: 32px !important;
}
.search-content .anticon svg {
  margin: -11px 0 0 0 !important;
}

.ant-input-feedback-icon {
  margin: -6px 0 0 0 !important;
}
.ant-message .anticon {
  top: -2px !important;
}
/*************************SCROLLBAR Starts*************************/

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #9fa0a1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

/*************************SCROLLBAR ENDS*************************/

.searchCss {
  background-color: white;
  color: black;
  margin-top: 0;
}

.searchCss a {
  color: black;
  margin-bottom: 2px;
  text-decoration: none;
}

/********************Media Responsive START********************/
@media (min-width: 1501px) {
  .trigger-phone {
    display: none !important;
  }
}
/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {
  .trigger-phone {
    display: none !important;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .trigger-phone {
    display: none !important;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991.98px) {
  .trigger-phone {
    display: none !important;
  }
}

/* small mobile :320px. */
@media (min-width: 576px) and (max-width: 767.98px) {
  .barcode-card {
    width: 100%;
    margin: 2% 0 0 0%;
  }

  .ant-breadcrumb {
    float: none;
    margin: 10px 0 0 0px !important;
  }
  .add-button {
    float: none !important;
    margin: 0 0 0 0px;
  }

  .card-line-details .stats-line-percent {
    text-align-last: left;
  }
  .ant-layout-sider {
    display: none;
  }
  .trigger {
    display: none !important;
  }
  .trigger-phone {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: rgb(255, 255, 255) !important;
    padding: 15px;
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
  }
  .trigger-phone:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: #fff !important;
    border-radius: 50% !important;
    overflow: visible !important;
  }
  .ant-drawer-body {
    padding: 10px 10px 10px 20px !important;
    top: 0px;
    left: 0px;
    z-index: 111;
    overflow: hidden !important;
    background-size: cover !important;
    background-position: center top !important;
    box-shadow: rgb(0 0 0 / 6%) 0px 5px 5px -3px,
      rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px !important;
    background-repeat: no-repeat !important;
    color: rgb(255, 255, 255) !important;
    transition: all 250ms ease-in-out 0s !important;
  }

  .pagination-div {
    float: none;
    margin: 0 0 0 23px;
  }
  .pages-header-scheduler {
    margin-bottom: 12px !important;
  }
  .pages-header {
    margin: 0 0 10px 0px;
  }
  .progress-bar {
    margin: 0 0 0 20px;
  }
  .Search-admin {
    margin: 0 0 0 20px;
  }

  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 301px !important;
  }
  .ant-layout-content {
    margin: 20px 0px !important;
    padding: 10px 5px !important;
  }
}

/* Large Mobile :480px. */
@media only screen and (max-width: 575.98px) {
  .barcode-card {
    width: 100%;
    margin: 2% 0 0 0%;
  }

  .ant-breadcrumb {
    float: none;
    margin: 10px 0 0 0px !important;
  }
  .add-button {
    float: none !important;
    margin: 0 0 0 0px;
  }

  .card-line-details .stats-line-percent {
    text-align-last: left;
  }
  .ant-layout-sider {
    display: none;
  }
  .trigger {
    display: none !important;
  }
  .trigger-phone {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: rgb(244, 244, 244) !important;
    padding: 15px;
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
  }
  .trigger-phone:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgb(255, 255, 255) !important;
    border-radius: 50% !important;
    overflow: visible !important;
  }
  .ant-drawer-body {
    padding: 10px 10px 10px 20px !important;
    top: 0px;
    left: 0px;
    z-index: 111;

    overflow: hidden !important;
    background-size: cover !important;
    background-position: center top !important;
    box-shadow: rgb(0 0 0 / 6%) 0px 5px 5px -3px,
      rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px !important;
    background-repeat: no-repeat !important;
    color: rgb(255, 255, 255) !important;
    transition: all 250ms ease-in-out 0s !important;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 310px !important;
  }
  .pagination-div {
    float: none;
    margin: 0 0 0 23px;
  }
  .pages-header {
    margin: 0 0 10px 0px;
  }
  .progress-bar {
    margin: 0 0 0 20px;
  }
  .Search-admin {
    margin: 0 0 0 20px;
  }
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 301px !important;
  }
  .ant-layout-content {
    margin: 20px 0px !important;
    padding: 10px 5px !important;
  }
}

/********************Media Responsive END********************/
.head-row {
  display: flex !important;
}
.item-head {
  margin: 0 35px 0 0 !important;
}
.item-details {
  margin: 0 50px 0 0 !important;
}
.item-markings {
  margin: 0 135px 0 0 !important;
}
.item-others {
  margin: 0 225px 0 0 !important;
}
.ant-picker-yash {
  width: 250px !important;
}
.ant-picker.ant-picker-yash {
  width: 250px !important;
}
.ant-picker {
  width: 473px !important;
}
.ant-picker.ant-picker-eventTable {
  width: 155px !important;
}

.icon-class {
  color: #252a45 !important;
  font-size: 18px !important;
}
happy .event-button {
  margin: 0, 0, 0, 0;
}

.page-title {
  font-size: 32px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1.5px;
  width: 100px;
  margin: 0px 0px -7px 0px;
  text-align: center;
  margin-right: 0;
  white-space: nowrap;
  color: #2c384af2;
}

.text-cover {
  display: inline-block;
  position: relative;
  width: 70%;
  max-width: 80ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
  margin: 0 0 0 25px;
}

.Home-icon {
  color: #252a45;
  font-size: 18px !important;
}
.ant-breadcrumb {
  padding: 15px 0 !important;
  float: left !important;
}
.add-Members {
  margin-top: 15px !important;
}
.schedule_event {
  display: inline-block;
  position: relative;
  width: 70%;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

/*****************Progress Bar****************************/
.progress-bar-yash {
  padding: 0 0px 0 0px;
}
.progress-bar-message {
  opacity: 0.8;
  margin: 0px 0 0 7px;
  width: 70px;
  font-size: 12px;
}
/********************** New UI ****************/
.innerbox {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.cardTitle {
  font-size: 2rem !important;
  font-weight: 549 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.card-name-and-style2 {
  margin-bottom: 10px !important;
  padding: 25px !important;
  background-image: linear-gradient(
    45deg,
    var(--cui-warning-start, #f7e59a 0),
    var(--cui-warning-stop, #e6b800 100%)
  );
}
.card-name-and-style3 {
  padding: 25px !important;
  background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #ec89a7 0),
    var(--cui-danger-stop, #d73263 100%)
  );
}
.card {
  --bs-card-border-color: none !important;
  border: none !important;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  border: none !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 20px 0px 20px 0 !important;
}

.logo-div {
  --cui-bg-opacity: 1;
  background-color: rgba(80, 70, 229, var(--cui-bg-opacity)) !important;
  margin: 0px 0px 0px -25px !important;
}

.sale-card {
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  margin-bottom: 0.5rem !important;
}

.ant-table {
  background: none !important;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  color: rgba(29, 27, 27, 0.85);
  font-variant: none !important;

  font-feature-settings: none !important;
  font-size: 15px !important;
}
.ant-table-thead > tr > th {
  color: #2c384a61 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
  background: none !important;
}

.ant-select-selection-item {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
}

.ant-picker-input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}
.broadcast-msg {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

.select-recp {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  color: #2c384af2;
}

.div-resp {
  margin: 20px 0px 8px 0px;
}

.ant-table-tbody {
  padding: 20px !important;
}
.ant-table-cell {
  padding: 12px !important;
}
.my-para {
  margin-bottom: 0em !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5046e5 !important;
  font-size: 16px !important;
}
.ant-tabs-tab:hover {
  color: #5046e5 !important;
}
.ant-tabs-tab-btn:hover {
  color: #5046e5 !important;
}

.ant-tabs-ink-bar {
  background: #5046e5 !important;
}

.ant-tabs-tab-btn {
  font-size: 16px !important;
}

.my-header {
  position: sticky;
  top: 0;
  z-index: 96;
}
.count-cards .details .progress-broadcast .sent-broadcast .anticon svg {
  margin: -6px 0 0 0;
  color: #3f8600 !important;
  font-size: 20px !important;
}
.count-cards .details .progress-broadcast .failed-broadcast .anticon svg {
  margin: -6px 0 0 0;
  color: #cf1322 !important;
  font-size: 20px !important;
}
.progress-broadcast {
  height: 100px;
  display: flex;
  width: 218px;
  padding: 0px 0px 0px 0px;
  margin: -21px -17px 0px 0px;
}
/*****************************Query**************************************/
.query-modal {
  margin: 2px 0 20px 16px;
}
.sendReply {
  text-align: center;
  margin-top: 10px;
}

.ok-modal-schedule-brd {
  background: #0060aa !important;
  border: 1px #0060aa !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;

  font-weight: 550;
  margin: 0 0 0 317px;
}

.select_event {
  margin: -16px 0 0 0 !important;
}

.my-col {
  margin: 0 0 0 30px;
}

.text-secondary {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.add-group {
  display: inline-block;
  background: #d73263 !important;
  color: #fff;
  border: 1px #d73263;
  border-color: #d73263 !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  cursor: pointer;
  letter-spacing: 2px;
}

.add-group:hover {
  background: #d73263 !important;
}

.custom-flex {
  display: unset !important;
}

.ok-modal-broadcast {
  background: #5046e5 !important;
  border: 1px #5046e5 !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  font-weight: 550;
}

.ant-upload.ant-upload-select-picture-card {
  width: 120px !important;
  height: 120px !important;
}
.download-btn {
  margin: 13px 5px 0px 0 !important;
}
.dwnld-btn {
  margin: -35px 0px 0 0;
}
.download-btn .ant-btn-lg {
  padding: 0 13px !important;
}
.download-btn .ant-btn-lg .anticon svg {
  margin: -8px 0 0 0 !important;
}
.download-btn .ant-btn-lg:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}
.btn-dwn {
  border: none !important;
  background: none !important;
  padding: 0px !important;
}

.drag-area {
  border: 1px dashed #3c3c3c;
  height: 140px;
  width: 130px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.drag-area:hover {
  border: 1px dashed #5046e5;
}
.drag-area-img {
  width: 130px;
  margin: 0px 0px 0px 110px;
}

.drag-area.active {
  border: 2px solid #fff;
}
.drag-area .icon {
  font-size: 100px;
  color: #fff;
}
.drag-area header {
  font-size: 15px;
  font-weight: 500;
  color: #3c3c3c;

  margin: 36px 0px 0 0;
}
.ant-image-mask {
  margin: 0px 35px 0 -35px !important;
}

.event_input {
  width: 410px !important;
}

.event_picker.ant-picker {
  width: 425px !important;
}

.ant-picker-input > input[disabled] {
  color: black !important ;
}

.spinLoader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: none !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-typography.ant-typography-success {
  font-size: medium !important;
  margin: 0 15px 0 0 !important;
}

.ant-modal-mask {
  background-color: rgba(135, 135, 135, 0.1) !important;
}
.search-name {
  margin-top: 0.5px;
}

.search-icon {
  display: flex;
  flex-direction: row;
}
.update-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
}

/********** CONVERSATION ********************/

.chat-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
}

.chat-bubble {
  max-width: 70%;
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
  word-wrap: break-word;
}

.sender {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.receiver {
  background-color: #fff;
  align-self: flex-start;
}

.message-text {
  font-size: 14px;
}

.chat-app {
  height: 65vh;
  display: flex;
  flex-direction: column;
  background-image: url("./assests/img/convoBg.png");
}

.message-timestamp {
  font-size: 12px;
  color: #777;
}
